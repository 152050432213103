/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.snackActionText {
    .mdc-snackbar__surface {
        @apply bg-gray-900 #{'!important'};
    }

    button {
        @apply text-primary-500 #{'!important'};
    }
}

.text-secondary {
    color: #000 !important;
}

mat-label {
    color: #000 !important;
}

.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {    
    border-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
}

.max-w-8xl {
    max-width: 88rem;
}

.dialog-content-height-scroll {
    max-height: calc(100vh - 205px);
    overflow-y: scroll;
    padding-right: 2px;
}

.grid-cols-13 {
    grid-template-columns: repeat(13,minmax(0,1fr)) !important;
}
.grid-cols-14 {
    grid-template-columns: repeat(14,minmax(0,1fr)) !important;
}
.grid-cols-15 {
    grid-template-columns: repeat(15,minmax(0,1fr)) !important;
}
.grid-cols-16 {
    grid-template-columns: repeat(16,minmax(0,1fr)) !important;
}
.grid-cols-17 {
    grid-template-columns: repeat(17,minmax(0,1fr)) !important;
}
.grid-cols-28 {
    grid-template-columns: repeat(28,minmax(0,1fr)) !important;
}

.col-span-13 {
    grid-column: span 13 / span 13 !important;
}
.col-span-14 {
    grid-column: span 14 / span 13 !important;
}
.col-span-15 {
    grid-column: span 15 / span 13 !important;
}
.col-span-16 {
    grid-column: span 16 / span 13 !important;
}
.col-span-17 {
    grid-column: span 17 / span 13 !important;
}

@media (min-width: 600px) {
    .sm\:grid-cols-13 {
        grid-template-columns: repeat(13,minmax(0,1fr)) !important;
    }
    .sm\:grid-cols-14 {
        grid-template-columns: repeat(14,minmax(0,1fr)) !important;
    }
    .sm\:grid-cols-15 {
        grid-template-columns: repeat(15,minmax(0,1fr)) !important;
    }
    .sm\:grid-cols-16 {
        grid-template-columns: repeat(16,minmax(0,1fr)) !important;
    }
    .sm\:grid-cols-17 {
        grid-template-columns: repeat(17,minmax(0,1fr)) !important;
    }
    .sm\:grid-cols-28 {
        grid-template-columns: repeat(28,minmax(0,1fr)) !important;
    }
}

//override the cursor on next editable stepper icon
::ng-deep .mat-step-header[aria-disabled=true]:first .mat-step-icon {
    cursor: pointer !important;
}
//hover?


div:has(a[href*="https://www.syncfusion.com/account/claim-license-key"])
{
  visibility: hidden;
}

